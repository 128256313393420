import { useGetIdentity } from '@refinedev/core';
import { Identity } from './auth';

export const useIsAdminUser = (): boolean | undefined => {
  const { data } = useGetIdentity<Identity>();

  return (
    data?.email.includes('@tallylabs.xyz') ||
    data?.email.includes('turtleartneil@gmail.com') ||
    data?.email.includes('lianenicholeee@gmail.com')
  );
};
